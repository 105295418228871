<script setup>
const props = defineProps({
  isVertical: {
    type: Boolean,
    default: false,
  },
});

const socialBannerDesktop = resolveComponent('common/HwSocialBanner/Desktop');
const socialBannerMobile = resolveComponent('common/HwSocialBanner/Mobile');
const socialBannerVertical = resolveComponent('common/HwSocialBanner/Vertical');

const { $mq } = useNuxtApp();

const isVertical = toRef(props, 'isVertical');

const getComponentVersion = computed(() => {
  return isVertical.value
    ? socialBannerVertical
    : $mq.isSmallOrMediumScreen?.value
      ? socialBannerMobile
      : socialBannerDesktop;
});
</script>

<template>
  <a
    href="https://w5az.app.link/hwstatic-banner"
    target="_blank"
    rel="noopener noreferrer"
    :aria-label="$t('t_SOCIALBANNERTITLE')"
    :aria-description="$t('t_SOCIALBANNERTEXT')"
    class="hw-social-banner"
  >
    <component :is="getComponentVersion" />
  </a>
</template>

<style scoped lang="scss">
.hw-social-banner {
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  background-repeat: no-repeat;
  background-position: left center;
  background-size: cover;
  background-image: url('@/public/images/social-banner/social-banner-bg-small.jpg');
  max-width: 1600px;
  margin: 0 auto;

  @include tablet {
    border-radius: $wds-border-radius-xxl;
  }

  @include tablet-large {
    background-position: center center;
    background-image: url('@/public/images/social-banner/social-banner-bg-large.jpg');
  }

  @include desktop {
    &:focus-visible {
      box-shadow: $border-focus-default;
    }
  }
}
</style>
